export default {
  name: 'PropostaEnviada',
  data () {
    return {
      slide: 1,
      menus: [
        {
          titulo: 'Agendar Delivery',
          descricao: 'Você está pronto para uma nova experiência de entrega?',
          icone: 'app:painel_agendar_delivery',
          rota: 'painel-delivery'
        },
        {
          titulo: 'Agendar Teste Drive',
          descricao: 'Aqui podemos entregar o carro ou você vem até nós.',
          icone: 'app:painel_agendar_test_drive',
          rota: 'painel-test-drive'
        },
        {
          titulo: 'Agendar Videochamada',
          descricao: 'Está pronto para conhecer o carro via videochamadas? Vamos nos falar?',
          icone: 'app:painel_agendar_videochamada',
          rota: 'painel-videochamada'
        },
        {
          titulo: 'Completar Proposta',
          descricao: 'Continue o processo de compra completando algumas informações.',
          icone: 'app:painel_completar_proposta',
          rota: 'painel-proposta'
        }
      ]
    }
  },
  computed: {
    arquivosUrl () {
      return process.env.arquivosUrl
    },
    imagemOriginalVeiculo () {
      return this.$store.getters['depois/getData']
    }
  },
  methods: {
    imagem () {
      const endereco = this.imagemOriginalVeiculo && this.imagemOriginalVeiculo.bem_arquivos && this.imagemOriginalVeiculo.bem_arquivos.length > 0
        ? this.arquivosUrl + this.imagemOriginalVeiculo.bem_arquivos[0]
        : null

      if (typeof endereco === 'undefined' || endereco === null) {
        return ''
      }
      if (typeof endereco === 'string') {
        let img = endereco.split('.')
        const extensao = img.pop()
        let nome = img.join('.')
        nome = `${nome}_original`
        img = `${nome}.${extensao}`
        return img
      } else {
        return ''
      }
    }
  }
}
